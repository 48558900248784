import React from "react";
import InfoContainer from "./InfoContainer";
import MainElement from "./MainElement";

function Template({
  blData,
  templatetype,
  overlayImg,
  videospath,
  imagespath,
  cdn,
  keyPresses,
}) {
  function isInfo() {
    if (templatetype === "blInfoVideoGallery") {
      return true;
    }
  }

  return (
    <div className="mainSlider" id="mainSlider">
      <div
        className={`${isInfo() ? "fullWrapper infoGal" : "fullWrapper"}`}
        id="fullWrapper"
      >
        <MainElement
          cdn={cdn}
          blData={blData}
          imagespath={imagespath}
          videospath={videospath}
          templatetype={templatetype}
          keyPresses={keyPresses}
        />
        {templatetype === "blInfoVideoGallery" ? (
          <InfoContainer
            cdn={cdn}
            templatetype={templatetype}
            blData={blData}
            imagespath={imagespath}
            keyPresses={keyPresses}
          />
        ) : null}
      </div>
      <div id="carouselWrapper" className="Section carouselWrapper">
        <div id="carouselSpacer" className="carouselSpacer">
          <div id="sliderTrack" className="sliderTrack">
            {blData.map((element, index) => (
              <div
                id={"thumb-" + index}
                className="carousel_thumb"
                key={index}
                style={{
                  width: `${70 / 5}vw`,
                  height: `${70 / 9.1}vw`,
                  backgroundImage: `url(${
                    cdn + imagespath + element.componentSrc
                  })`,
                  backgroundSize: "15.4vw",
                  backgroundPositionX: "0vw",
                  backgroundPositionY: "0.2vw",
                }}
              ></div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Template;
