import DataFetching from "./lib/DataFetching";

function App() {
  return (
    <>
      <DataFetching />
    </>
  );
}

export default App;
