import React from "react";

function MainElement({
  cdn,
  blData,
  imagespath,
  videospath,
  templatetype,
  keyPresses,
}) {
  function checkPosition(current, elindex, resource) {
    if (current === elindex) {
      return (
        <video key={0} id="activeVideo" controls>
          {resource}
        </video>
      );
    }
  }

  function checkImage(current, elindex, resource) {
    if (current === elindex) {
      return resource;
    }
  }

  return (
    <div className="mainContainer" id="mainContainer">
      {templatetype === "blInfoVideoGallery" ||
      templatetype === "blVideoGallery" ? (
        <div>
          {blData.map((element, index) =>
            checkPosition(
              index,
              keyPresses,
              <source src={cdn + videospath + element.videoSrc}></source>
            )
          )}
        </div>
      ) : null}
      {templatetype === "blImageGallery" ? (
        <div className="mainContainer" id="mainContainer">
          {blData.map((element, index) =>
            checkImage(
              index,
              keyPresses,
              <div
                className="main_img"
                key={index}
                style={{
                  backgroundImage: `url(${
                    cdn + imagespath + element.displayImgSrc
                  })`,
                }}
              ></div>
            )
          )}
        </div>
      ) : null}
    </div>
  );
}

export default MainElement;
