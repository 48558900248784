import React, { useState, useEffect } from "react";
import Template from "../components/Template";
import { useKey } from "react-keyboard-hooks";

function DataFetching() {
  const [blData, setBlData] = useState([]);
  const [templatetype, setTemplatetype] = useState("");
  const [overlayImg, setOverlayImg] = useState("");
  const [videospath, setVideospath] = useState("");
  const [imagespath, setImagespath] = useState("");
  const cdn = "//cdn-media.brightline.tv/csaad/v3/assets/";
  const [keyPresses, setKeyPresses] = useState(0);

  const nextElement = () => {
    if (keyPresses < blData.length - 1) {
      setKeyPresses((previousPresses) => (previousPresses += 1));
      _changeValue(keyPresses + 1);
    }
  };

  const prevElement = () => {
    if (keyPresses > 0) {
      setKeyPresses(
        (previousPresses) => (previousPresses = previousPresses - 1)
      );
      _changeValue(keyPresses - 1);
    }
  };

  const customlogger = () => {
    if (!console) {
      console = {};
    }
    var logger = document.querySelector("#log ul");
    console.log = function (message) {
      if (typeof message == "object") {
        logger.innerHTML +=
          "<li>" +
          (JSON && JSON.stringify ? JSON.stringify(message) : String(message)) +
          "</li>";
      } else {
        logger.innerHTML += "<li>" + message + "</li>";
      }
    };
  };

  const hideLogs = () => {
    document.getElementById("log").style.display = "none";
  };

  const showLogs = () => {
    document.getElementById("log").style.display = "block";
  };

  const triggerElements = () => {
    const creative_holder = document.getElementById("creative_holder");
    if (!creative_holder.classList.contains("adIsRunning")) {
      playAd();
    }
    var listofThumbs = document.querySelectorAll(".carousel_thumb");
    listofThumbs.forEach((thumb) => {
      if (thumb.classList.contains("active")) {
        thumb.classList.remove("active");
      }
    });
    document.getElementById("thumb-" + keyPresses).classList.add("active");
    document.getElementById("thumb-" + keyPresses).style.backgroundPositionY =
      "-23.1vw";

    document.getElementById(
      "mainContainer"
    ).innerHTML = `<video id="activeVideo"><source src=${
      cdn + videospath + blData[keyPresses].videoSrc
    }></source></video>`;

    document.getElementById(
      "infoContainer"
    ).innerHTML = `<div style="background-image:url(${
      cdn + imagespath + blData[keyPresses].displayImgSrc
    })"></div>`;

    document.getElementById("activeVideo").play();
  };

  const _changeValue = (element) => {
    var listofThumbs = document.querySelectorAll(".carousel_thumb");
    listofThumbs.forEach((thumb) => {
      if (thumb.classList.contains("focus")) {
        thumb.classList.remove("focus");
        thumb.style.backgroundPositionX = "0vw";
        thumb.style.backgroundPositionY = "0.2vw";
      }
    });
    let activeThumb = document.getElementById("thumb-" + element);
    activeThumb.classList.add("focus");
    activeThumb.style.backgroundPositionX = "-.7vw";
    activeThumb.style.backgroundPositionY = "-7.7vw";
    activeThumb.focus();
  };

  useKey("ArrowRight", nextElement);
  useKey("ArrowLeft", prevElement);
  useKey("Enter", triggerElements);
  useKey("Escape", hideLogs);
  useKey("Backquote", showLogs);
  // triggerElements();

  useEffect(() => {
    getBLdata();
    customlogger();
  }, []);

  const playAd = () => {
    document.getElementById("overlayImg").style.display = "none";
    document.getElementById("mainSlider").style.opacity = 1;
    document.getElementById("creative_holder").classList.add("adIsRunning");
    triggerElements();
  };

  const getBLdata = async () => {
    // const response = await fetch("./assets/json/blImageGallery.json");
    // const response = await fetch("./assets/json/blVideoGallery.json");
    const response = await fetch("./assets/json/blInfoVideoGallery.json");

    const data = await response.json();

    //Get the Overlay resources
    setOverlayImg(data.overlay.components[0].componentSrc);

    //Get the Template type
    setTemplatetype(data.microsite.features[0].navigationItem.featureName);

    //Get the video resources path
    setVideospath(data.resourcePath.videos);

    //Get the images resources path
    setImagespath(data.resourcePath.images);

    //Get the Template resources
    setBlData(data.microsite.features[0].components[3].components);

    _changeValue(keyPresses);
  };

  return (
    <>
      <div id="log">
        <ul></ul>
      </div>
      <div
        onClick={playAd}
        className="overlayImg"
        id="overlayImg"
        style={{ backgroundImage: `url(${cdn + imagespath + overlayImg})` }}
      ></div>
      <Template
        blData={blData}
        templatetype={templatetype}
        overlayImg={overlayImg}
        videospath={videospath}
        imagespath={imagespath}
        cdn={cdn}
      />
    </>
  );
}

export default DataFetching;
