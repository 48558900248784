import React from "react";
function InfoContainer({ cdn, blData, imagespath, keyPresses }) {
  return (
    <div className="infoContainer" id="infoContainer">
      {blData.map((element, index) => (
        <div
          key={index}
          style={{
            backgroundImage: `url(${cdn + imagespath + element.displayImgSrc})`,
          }}
        ></div>
      ))}
    </div>
  );
}

export default InfoContainer;
